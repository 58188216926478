//
// Project styles
//

@import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-pro/scss/brands.scss";
@import "@fortawesome/fontawesome-pro/scss/solid.scss";
@import "@fortawesome/fontawesome-pro/scss/v4-shims.scss";
@import "@fortawesome/fontawesome-pro/scss/duotone.scss";
@import "@fortawesome/fontawesome-pro/scss/_duotone-icons.scss";

body {
  background-color: #ee2c36;
}

body img.owl-logo {
  max-width: 120px;
}

body img.ow-logo {
  max-width: 280px;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-name,
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-email
{
  max-width: 12rem;
}

.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky>* {
  top: 57px;
}
